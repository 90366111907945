import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";


const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`;


const metadata = ({ description, title, author, meta, keywords }) => {
    return [
        { name: "description", content: description },
        { property: "og:title", content: title },
        { property: "og:description", content: description },
        { property: "og:type", content: "website" },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:creator", content: author },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: description }
    ]
        .concat(
            keywords.length > 0
                ? { name: "keywords", content: keywords.join( ", " ) }
                : []
        )
        .concat( meta )
    ;
};


const SEO = ({ lang, ...props }) =>
    <StaticQuery
        query={ detailsQuery }
        render={ ({ site: { siteMetadata } }) =>
            <Helmet
                htmlAttributes={{ lang }}
                title={ props.title }
                titleTemplate={ `%s — ${siteMetadata.title}` }
                defaultTitle={ siteMetadata.title }
                meta={ metadata({
                    ...siteMetadata,
                    ...props
                }) }
            />
        }
    />
;

SEO.defaultProps = {
    lang: "en",
    meta: [],
    keywords: []
};

export default SEO;
