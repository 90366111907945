import React from "react";

import Screen from "../components/screen";
import SEO from "../components/seo";

const NotFoundPage = () => (
    <Screen>
        <SEO title="404: Not found" />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Screen>
);

export default NotFoundPage;
