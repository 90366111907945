import React from "react";

import styles from "./screen.module.css";


const Screen = ({ children }) =>
    <div className={ styles.root } >
        { children }
    </div>
;

export default Screen;
